.accueil {
  --url-bg-image: var(--url-icon-accueil);
  --alinea-left-right: 3rem;
  --alinea-left-right-biblio: 3rem;
  --alinea-top-right-biblio: 9rem;
  --content-width: 70%;
  --page-margin-horizontal: 12.5rem;
  flex-direction: column;
  display: flex;
}

@media (width <= 1279px) {
  .accueil {
    --alinea-left-right: 3rem;
    --alinea-left-right-biblio: 3rem;
    --alinea-top-right-biblio: 6rem;
    --content-width: 100%;
    --page-margin-horizontal: 0;
  }
}

.accueil-bienvenue {
  font: normal normal normal 2.5rem / 3rem var(--secondary-font-family);
  letter-spacing: 0;
  color: var(--theme-primary);
  padding: 1em var(--alinea-left-right);
}

@media (width <= 1279px) {
  .accueil-bienvenue {
    font: normal normal normal 2rem / 2.5rem var(--secondary-font-family);
  }
}

.accueil-edito {
  background-color: var(--theme-surface-secondary);
  color-rendering: var(--theme-on-surface-secondary);
  padding: 1em var(--alinea-left-right);
  max-width: var(--content-width);
}

.accueil-edito-title {
  font: normal normal 500 1.3rem / 2rem var(--secondary-font-family);
  margin: 0 0 1rem;
}

.accueil-edito-content {
  font: normal normal normal 1rem var(--primary-font-family);
}

.accueil-edito-content h1 {
  margin: 1rem 0 0;
  font-size: 1.1rem;
  font-weight: normal;
}

.accueil-edito-content h2, .accueil-edito-content h3 {
  margin: 1rem 0 0;
  font-size: 1rem;
  font-weight: normal;
}

.accueil-edito-content p {
  text-align: justify;
  margin: 0;
}

.accueil-nos-etudes {
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  font: normal normal 600 1.2rem / 1.5rem var(--secondary-font-family);
  right: var(--alinea-left-right);
  top: var(--alinea-left-right);
  max-width: calc(100% - var(--content-width)  - 2rem - var(--alinea-left-right));
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  text-decoration: none;
  display: flex;
  position: absolute;
}

.accueil-nos-etudes svg {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

@media (width <= 1279px) {
  .accueil-nos-etudes {
    position: initial;
    font: normal normal 600 1.25rem / 1.25rem var(--secondary-font-family);
    max-width: initial;
    align-self: flex-end;
    width: 50vw;
    padding: 1.5rem;
  }

  .accueil-nos-etudes svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media (width <= 800px) {
  .accueil-nos-etudes {
    font: normal normal 600 1.1rem / 1.1rem var(--secondary-font-family);
    width: initial;
    align-self: stretch;
    padding: 1.5rem;
  }

  .accueil-nos-etudes svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.accueil-nos-biblios {
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  font: normal normal 600 1.2rem / 1.5rem var(--secondary-font-family);
  right: var(--alinea-left-right-biblio);
  top: var(--alinea-top-right-biblio);
  max-width: calc(100% - var(--content-width)  - 2rem - var(--alinea-left-right-biblio));
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  text-decoration: none;
  display: flex;
  position: absolute;
}

.accueil-nos-biblios svg {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

@media (width <= 1279px) {
  .accueil-nos-biblios {
    position: initial;
    font: normal normal 600 1.25rem / 1.25rem var(--secondary-font-family);
    max-width: initial;
    align-self: flex-end;
    width: 50vw;
    margin-top: 1rem;
    padding: 1.5rem;
  }

  .accueil-nos-biblios svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media (width <= 800px) {
  .accueil-nos-biblios {
    font: normal normal 600 1.1rem / 1.1rem var(--secondary-font-family);
    width: initial;
    align-self: stretch;
    padding: 1.5rem;
  }

  .accueil-nos-biblios svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.accueil-row {
  padding: 1em var(--alinea-left-right);
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;
  display: flex;
}

@media (width <= 1800px) {
  .accueil-row {
    column-gap: 3rem;
  }
}

@media (width <= 1440px) {
  .accueil-row {
    column-gap: 1rem;
  }
}

@media (width <= 1279px) {
  .accueil-row {
    flex-direction: column;
    padding: 1em 0;
  }

  .accueil-row .title {
    padding: 0 var(--alinea-left-right);
  }
}

.accueil-image-du-jour {
  flex-direction: column;
  display: flex;
}

.accueil-image-du-jour img {
  width: auto;
  max-width: 65vw;
  max-height: 70vh;
  display: block;
}

@media (width <= 1800px) {
  .accueil-image-du-jour img {
    max-width: 45vw;
    max-height: 55vh;
  }
}

@media (width <= 1440px) {
  .accueil-image-du-jour img {
    max-width: 35vw;
    max-height: 50vh;
  }
}

@media (width >= 1440px) and (-webkit-device-pixel-ratio >= 1.25), (width >= 1200px) and (-webkit-device-pixel-ratio >= 1.5) {
  .accueil-image-du-jour img {
    max-width: 45vw;
    max-height: 55vh;
  }
}

@media (width <= 1279px) {
  .accueil-image-du-jour .image-container {
    justify-content: center;
    display: flex;
  }

  .accueil-image-du-jour img {
    max-width: 100vw;
    max-height: 75vh;
  }
}

.accueil-image-du-jour .image-indispo svg {
  color: var(--theme-primary);
  width: 25rem;
  height: 25rem;
}

.accueil-row .title {
  font: normal normal 500 1.5rem / 4rem var(--secondary-font-family);
}

.accueil-lumiere-sur {
  flex-direction: column;
  display: flex;
}

.accueil-lumiere-sur-content {
  background: linear-gradient(90deg, var(--theme-secondary) 80%, transparent 80%);
  flex-direction: row;
  flex-grow: 1;
  gap: 2rem;
  padding: 2em;
  display: flex;
}

.accueil-lumiere-sur-content-text {
  color: var(--theme-on-secondary);
  flex-direction: column;
  flex: 1;
  row-gap: 1rem;
  max-width: 35vw;
  padding-right: .5em;
  display: flex;
}

@media (width <= 1440px) {
  .accueil-lumiere-sur-content-text {
    max-width: 25vw;
  }
}

@media (width >= 1440px) and (-webkit-device-pixel-ratio >= 1.25), (width >= 1200px) and (-webkit-device-pixel-ratio >= 1.5) {
  .accueil-lumiere-sur-content-text {
    max-width: 30vw;
  }
}

.accueil-lumiere-sur-content-text-title {
  font: normal normal 500 1.5rem / 1.875rem var(--secondary-font-family);
}

.accueil-lumiere-sur-content-text-title a {
  color: currentColor;
  text-decoration: none;
  display: block;
}

.accueil-lumiere-sur-content-text-title a:hover, .accueil-lumiere-sur-content-text-title a:active {
  text-decoration: underline;
}

.accueil-lumiere-sur-localisation {
  font: normal normal 600 1.25rem / 1.375rem var(--secondary-font-family);
}

.accueil-lumiere-sur-localisation a {
  color: currentColor;
  text-decoration: none;
}

.accueil-lumiere-sur-localisation a:hover, .accueil-lumiere-sur-localisation a:active {
  text-decoration: underline;
}

.accueil-lumiere-sur-texte-libre h1 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.accueil-lumiere-sur-texte-libre h2 {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.275rem;
}

.accueil-lumiere-sur-texte-libre h3 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.175rem;
}

.accueil-lumiere-sur-lien {
  flex: 1;
  align-items: flex-end;
  display: flex;
}

.accueil-lumiere-sur-lien a {
  font: normal normal 700 1rem var(--primary-font-family);
  background-color: var(--theme-on-primary);
  color: var(--theme-primary);
  align-items: center;
  padding: 1em 2em;
  text-decoration: none;
  display: flex;
}

.accueil-lumiere-sur-lien a .icon-lien-dossier {
  margin-left: .5em;
}

.accueil-lumiere-sur-lien a:hover {
  background-color: var(--theme-primary);
  color: var(--theme-on-primary);
}

.accueil-lumiere-sur-lien svg {
  width: 2rem;
  margin-left: 1em;
}

.accueil-lumiere-sur-content-image {
  flex: 0;
  align-self: center;
}

.accueil-lumiere-sur-content-image .image-container {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.accueil-lumiere-sur-content-image img {
  object-fit: contain;
  box-shadow: 1.25rem 1.25rem 0rem var(--theme-primary);
  width: auto;
  max-width: 25vw;
  max-height: 35vh;
  display: block;
}

@media (width <= 1800px) {
  .accueil-lumiere-sur-content-image img {
    max-width: 20vw;
    max-height: 25vh;
  }
}

@media (width <= 1440px) {
  .accueil-lumiere-sur-content-image img {
    max-width: 15vw;
    max-height: 20vh;
  }
}

@media (width >= 1440px) and (-webkit-device-pixel-ratio >= 1.25), (width >= 1200px) and (-webkit-device-pixel-ratio >= 1.5) {
  .accueil-lumiere-sur-content-image img {
    max-width: 25vw;
    max-height: 35vh;
  }
}

@media (width <= 1279px) {
  .accueil-lumiere-sur-content {
    background: none;
    background-color: var(--theme-secondary);
    flex-direction: column;
    padding: 0 2rem;
  }

  .accueil-lumiere-sur-content .accueil-lumiere-sur-content-image {
    order: 1;
  }

  .accueil-lumiere-sur-content .accueil-lumiere-sur-content-text {
    max-width: initial;
    order: 2;
  }

  .accueil-lumiere-sur-content-image img {
    box-shadow: none;
    max-width: 100vw;
    max-height: 75vh;
  }

  .accueil-lumiere-sur-lien {
    margin: 0 0 1rem;
  }
}

.accueil-lumiere-sur-content-image .image-indispo svg {
  color: var(--theme-secondary);
  background-color: var(--theme-on-secondary);
  box-shadow: 1.25rem 1.25rem 0rem var(--theme-primary);
  width: 19rem;
  height: 19rem;
  padding: 2rem;
}

.accueil .carousel {
  margin: 3rem var(--page-margin-horizontal);
}

@media (width <= 1279px) {
  .accueil .carousel {
    margin: 3rem 0;
  }
}

.carousel {
  --carousel-title-size: 1.5rem;
  --carousel-vignette-size: 6.875rem;
  --carousel-element-padding: 1.25rem;
  --carousel-container-padding: .3rem;
  --carousel-container-column-gap: 1.875rem;
  --carousel-pagination-button-size: 3rem;
  --carousel-column-number: 4;
  --carousel-width: min(100vw, var(--largeur-maximal));
  --carousel-margin-horizontal: var(--page-margin-horizontal);
  position: relative;
}

.preview .carousel {
  --carousel-width: 37.5rem;
  --carousel-column-number: 1;
  --carousel-margin-horizontal: var(--preview-margin-horizontal);
  --carousel-vignette-size: 5.5rem;
  --carousel-title-size: 1.5rem;
}

@media (width <= 1919px) {
  .carousel {
    --carousel-column-number: 3;
  }

  .preview .carousel {
    --carousel-column-number: 1;
    --carousel-width: 28rem;
    --carousel-margin-horizontal: 0rem;
  }
}

@media (width <= 1500px) {
  .carousel {
    --carousel-column-number: 2;
  }

  .preview .carousel {
    --carousel-column-number: 1;
  }
}

@media (width <= 1279px) {
  .carousel {
    --carousel-column-number: 1;
    --page-margin-horizontal: 5rem;
    --carousel-container-column-gap: 2rem;
    --carousel-vignette-size: 12.5rem;
  }
}

@media (width <= 768px) {
  .carousel {
    --carousel-column-number: 1;
    --page-margin-horizontal: .5rem;
  }
}

.carousel-title {
  text-align: center;
  font: normal normal 500 var(--carousel-title-size) var(--secondary-font-family);
  color: var(--theme-on-surface-title);
  margin: 0;
}

@media (width <= 1279px) {
  .carousel-title {
    font-size: 2rem;
  }
}

.carousel-container {
  box-sizing: border-box;
  justify-content: space-between;
  column-gap: var(--carousel-container-column-gap);
  width: calc(var(--carousel-width)  - var(--carousel-margin-horizontal) * 2 - 1rem);
  padding: var(--carousel-container-padding);
  height: calc(var(--carousel-vignette-size)  + var(--carousel-element-padding) * 2 + var(--carousel-container-padding) * 2);
  display: flex;
  overflow-x: hidden;
}

@media (width <= 1279px) {
  .carousel-container {
    width: calc(100vw - var(--page-margin-horizontal) * 2);
    margin: 0 var(--page-margin-horizontal);
    height: auto;
  }
}

.carousel-element {
  box-sizing: border-box;
  width: calc(( (var(--carousel-width)  - var(--carousel-margin-horizontal) * 2 - 1rem)  - (var(--carousel-container-padding) * 2)  - ( var(--carousel-container-column-gap) * (var(--carousel-column-number)  - 1))) / var(--carousel-column-number));
  padding: var(--carousel-element-padding);
  box-shadow: 0rem .1875rem .375rem var(--theme-box-shadow-color);
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  text-decoration: none;
  transition: transform .3s ease-in;
  display: flex;
}

@media (width <= 1279px) {
  .carousel-element {
    width: calc(100vw - var(--page-margin-horizontal) * 2 - var(--carousel-container-padding) * 2);
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    padding: 0;
  }
}

.carousel-element:hover {
  box-shadow: -.0625rem .375rem .375rem var(--theme-box-shadow-color);
}

.carousel-element:active {
  box-shadow: -.125rem .5625rem .375rem var(--theme-box-shadow-color);
}

.carousel-element-vignette, .carousel-element .image-indispo svg {
  width: var(--carousel-vignette-size);
  height: var(--carousel-vignette-size);
  border-radius: var(--carousel-vignette-size);
  object-fit: cover;
  color: var(--theme-primary);
}

@media (width <= 1279px) {
  .carousel-element-vignette, .carousel-element .image-indispo svg {
    object-fit: cover;
    border-radius: 0;
    width: 100%;
  }
}

.carousel-element-body {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  row-gap: .6rem;
  display: flex;
}

@media (width <= 1279px) {
  .carousel-element-body {
    padding: 0 var(--carousel-element-padding) var(--carousel-element-padding) var(--carousel-element-padding);
  }
}

.carousel-element-tico {
  font: normal normal bold 1.125rem / 1.625rem var(--secondary-font-family);
  color: var(--theme-secondary);
  margin: 0;
}

.carousel-element-tico a {
  color: currentColor;
  overflow-wrap: anywhere;
  text-decoration: none;
}

.carousel-element-tico a:hover, .carousel-element-tico a:active {
  text-decoration: underline;
}

.carousel-element-localisation {
  font: normal normal normal 1rem / 1.375rem var(--primary-font-family);
  color: var(--theme-on-surface-title);
}

.carousel-element-localisation a {
  color: currentColor;
  text-decoration: none;
  display: inline-block;
}

.carousel-element-localisation a:hover, .carousel-element-localisation a:active {
  text-decoration: underline;
}

.carousel-pagination-btn {
  background-color: var(--theme-on-background);
  color: var(--theme-background);
  border-radius: var(--carousel-pagination-button-size);
  width: var(--carousel-pagination-button-size);
  height: var(--carousel-pagination-button-size);
  cursor: pointer;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
}

.carousel-pagination-btn svg {
  width: calc(var(--carousel-pagination-button-size)  - 1.5rem);
  height: calc(var(--carousel-pagination-button-size)  - 1.5rem);
}

.carousel-pagination-btn:hover {
  filter: brightness(125%);
}

.carousel-pagination-btn:active {
  filter: brightness(150%);
}

.carousel-pagination-btn[disabled] {
  opacity: .3;
  pointer-events: none;
  cursor: default;
}

.carousel-pagination-btn[data-pagination="previous"] {
  left: -6.25rem;
}

.carousel-pagination-btn[data-pagination="next"] {
  right: -6.25rem;
}

@media (width <= 1919px) {
  .preview .carousel-pagination-btn[data-pagination="previous"] {
    left: calc(-1 * var(--carousel-pagination-button-size)  - .5rem);
  }

  .preview .carousel-pagination-btn[data-pagination="next"] {
    right: calc(-1 * var(--carousel-pagination-button-size)  - .5rem);
  }
}

@media (width <= 1279px) {
  .carousel-pagination-btn[data-pagination="previous"] {
    left: 1rem;
  }

  .carousel-pagination-btn[data-pagination="next"] {
    right: 1rem;
  }
}

.line-clamped {
  --line-clamped-color: var(--theme-primary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamped:not(.opened) .line-clamp-fixed-subelement {
  display: inline;
}

.line-clamped:not(.opened) .line-clamp-fixed-subelement ~ .line-clamp-fixed-subelement:before {
  content: " ";
  display: block;
}

.line-clamped-show-all {
  margin-top: 1em;
}

.line-clamped-show-all, .line-clamped-show-all input[type="checkbox"] {
  align-items: center;
  column-gap: .3125rem;
  display: none;
}

.line-clamped-show-all label {
  font: normal normal bold .875rem / 1.125rem var(--primary-font-family);
  color: var(--line-clamped-color);
  cursor: pointer;
  align-items: center;
  column-gap: .3125rem;
  display: flex;
}

.line-clamped-show-all svg {
  fill: var(--line-clamped-color);
  height: 1.25rem;
}

/*# sourceMappingURL=accueil.80bad7f6.css.map */
