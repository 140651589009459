.carousel {
  --carousel-title-size: 1.5rem;
  --carousel-vignette-size: 6.875rem;
  --carousel-element-padding: 1.25rem;
  --carousel-container-padding: 0.3rem;
  --carousel-container-column-gap: 1.875rem;
  --carousel-pagination-button-size: 3rem;
  --carousel-column-number: 4;
  --carousel-width: min(100vw, var(--largeur-maximal));
  --carousel-margin-horizontal: var(--page-margin-horizontal);

  position: relative;
}

.preview .carousel {
  --carousel-width: 37.5rem;
  --carousel-column-number: 1;
  --carousel-margin-horizontal: var(--preview-margin-horizontal);
  --carousel-vignette-size: 5.5rem;
  --carousel-title-size: 1.5rem;
}

@media (max-width: 1919px) {
  .carousel {
    --carousel-column-number: 3;
  }

  .preview .carousel {
    --carousel-column-number: 1;
    --carousel-width: 28rem;
    --carousel-margin-horizontal: 0rem;
  }
}

@media (max-width: 1500px) {
  .carousel {
    --carousel-column-number: 2;
  }

  .preview .carousel {
    --carousel-column-number: 1;
  }
}

@media (max-width: 1279px) {
  .carousel {
    --carousel-column-number: 1;
    --page-margin-horizontal: 5rem;
    --carousel-container-column-gap: 2rem;
    --carousel-vignette-size: 12.5rem;
  }
}

@media (max-width: 768px) {
  .carousel {
    --carousel-column-number: 1;
    --page-margin-horizontal: 0.5rem;
  }
}

.carousel-title {
  text-align: center;
  font: normal normal 500 var(--carousel-title-size)
    var(--secondary-font-family);
  color: var(--theme-on-surface-title);
  margin: 0;
}

@media (max-width: 1279px) {
  .carousel-title {
    font-size: 2rem;
  }
}

.carousel-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  column-gap: var(--carousel-container-column-gap);
  width: calc(
    var(--carousel-width) - var(--carousel-margin-horizontal) * 2 - 1rem
  );

  padding: var(--carousel-container-padding);
  overflow-x: hidden;
  height: calc(
    var(--carousel-vignette-size) + var(--carousel-element-padding) * 2 +
      var(--carousel-container-padding) * 2
  );
}

@media (max-width: 1279px) {
  .carousel-container {
    width: calc(100vw - var(--page-margin-horizontal) * 2);
    height: auto;
    margin: 0 var(--page-margin-horizontal);
  }
}

.carousel-element {
  flex-shrink: 0;
  flex-grow: 0;

  box-sizing: border-box;
  display: flex;
  gap: 1.25rem;
  width: calc(
    (
        (var(--carousel-width) - var(--carousel-margin-horizontal) * 2 - 1rem) -
          (var(--carousel-container-padding) * 2) -
          (
            var(--carousel-container-column-gap) *
              (var(--carousel-column-number) - 1)
          )
      ) / var(--carousel-column-number)
  );
  padding: var(--carousel-element-padding);
  box-shadow: 0rem 0.1875rem 0.375rem var(--theme-box-shadow-color);
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in;
  text-decoration: none;
}

@media (max-width: 1279px) {
  .carousel-element {
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 0;
    width: calc(
      100vw - var(--page-margin-horizontal) * 2 -
        var(--carousel-container-padding) * 2
    );
  }
}

.carousel-element:hover {
  box-shadow: -0.0625rem 0.375rem 0.375rem var(--theme-box-shadow-color);
}

.carousel-element:active {
  box-shadow: -0.125rem 0.5625rem 0.375rem var(--theme-box-shadow-color);
}

.carousel-element-vignette,
.carousel-element .image-indispo svg {
  width: var(--carousel-vignette-size);
  height: var(--carousel-vignette-size);
  border-radius: var(--carousel-vignette-size);
  object-fit: cover;
  color: var(--theme-primary);
}

@media (max-width: 1279px) {
  .carousel-element-vignette,
  .carousel-element .image-indispo svg {
    width: 100%;
    border-radius: 0;
    object-fit: cover;
  }
}

.carousel-element-body {
  align-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.6rem;
}

@media (max-width: 1279px) {
  .carousel-element-body {
    padding: 0 var(--carousel-element-padding) var(--carousel-element-padding)
      var(--carousel-element-padding);
  }
}

.carousel-element-tico {
  margin: 0;

  font: normal normal bold 1.125rem/1.625rem var(--secondary-font-family);
  color: var(--theme-secondary);
}
.carousel-element-tico a {
  text-decoration: none;
  color: currentColor;
  overflow-wrap: anywhere;
}
.carousel-element-tico a:hover,
.carousel-element-tico a:active {
  text-decoration: underline;
}

.carousel-element-localisation {
  font: normal normal normal 1rem / 1.375rem var(--primary-font-family);
  color: var(--theme-on-surface-title);
}
.carousel-element-localisation a {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
}
.carousel-element-localisation a:hover,
.carousel-element-localisation a:active {
  text-decoration: underline;
}

.carousel-pagination-btn {
  position: absolute;
  top: 50%;
  padding: 0;
  background-color: var(--theme-on-background);
  color: var(--theme-background);
  border: none;
  border-radius: var(--carousel-pagination-button-size);

  width: var(--carousel-pagination-button-size);
  height: var(--carousel-pagination-button-size);

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-pagination-btn svg {
  width: calc(var(--carousel-pagination-button-size) - 1.5rem);
  height: calc(var(--carousel-pagination-button-size) - 1.5rem);
}

.carousel-pagination-btn:hover {
  filter: brightness(125%);
}

.carousel-pagination-btn:active {
  filter: brightness(150%);
}

.carousel-pagination-btn[disabled] {
  opacity: 0.3;
  pointer-events: none;

  cursor: default;
}

.carousel-pagination-btn[data-pagination='previous'] {
  left: -6.25rem;
}

.carousel-pagination-btn[data-pagination='next'] {
  right: -6.25rem;
}

@media (max-width: 1919px) {
  .preview .carousel-pagination-btn[data-pagination='previous'] {
    left: calc(-1 * var(--carousel-pagination-button-size) - 0.5rem);
  }

  .preview .carousel-pagination-btn[data-pagination='next'] {
    right: calc(-1 * var(--carousel-pagination-button-size) - 0.5rem);
  }
}

@media (max-width: 1279px) {
  .carousel-pagination-btn[data-pagination='previous'] {
    left: 1rem;
  }

  .carousel-pagination-btn[data-pagination='next'] {
    right: 1rem;
  }
}
